
import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { Articolo } from '~/types/models/Articolo'

@Component
export default class TheArticoli extends Vue {
  @Prop() articoli!: Articolo[]
  @Prop() pending!: boolean

  @Prop() error!: boolean

  public getImage(item: Articolo) {
    if (!item?.assets?.length) {
      return '/img/post-thumb-2.jpg'
    }
    const image = item.assets.find(
      (asset) => asset.assetableField === 'mainImage'
    )
    if (!image) {
      return '/img/post-thumb-2.jpg'
    }
    return this.$config.cdnEndpoint + '512/' + image.originalName
  }

  public calculateCol(number: number) {
    if (number === 1) {
      return 'col-12'
    }
    if (number === 2) {
      return 'col-12 col-md-6'
    }
    return 'col-12 col-md-6 col-lg-4'
  }
}
