import { render, staticRenderFns } from "./TheConvegni.vue?vue&type=template&id=1503a204&scoped=true&"
import script from "./TheConvegni.vue?vue&type=script&lang=ts&"
export * from "./TheConvegni.vue?vue&type=script&lang=ts&"
import style0 from "./TheConvegni.vue?vue&type=style&index=0&id=1503a204&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1503a204",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ElementsSkeletonsTheCorsi: require('/usr/src/nuxt-app/components/elements/skeletons/TheCorsi.vue').default,ElementsTheError: require('/usr/src/nuxt-app/components/elements/TheError.vue').default})
