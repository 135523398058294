
import { Component, Vue, Prop } from 'nuxt-property-decorator'

type Carousel = {
  title: string
  image?: string
  link?: string | null
}

@Component
export default class TheCarousel extends Vue {
  @Prop() items!: Carousel[]
}
