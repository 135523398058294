
import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { Convegno } from '~/types/models/Convegno'

@Component
export default class TheConvegni extends Vue {
  @Prop() convegni!: Convegno[]
  @Prop() pending!: boolean

  @Prop() error!: boolean

  public getImage(item: Convegno) {
    if (!item?.assets?.length) {
      return '/img/post-thumb-2.jpg'
    }
    const image = item.assets.find(
      (asset) => asset.assetableField === 'mainImage'
    )
    if (!image) {
      return '/img/post-thumb-2.jpg'
    }
    return this.$config.cdnEndpoint + '512/' + image.originalName
  }
}
