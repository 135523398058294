
import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { Corso } from '~/types/models/Corso'

@Component
export default class TheCorsi extends Vue {
  @Prop() corsi!: Corso[]
  @Prop() pending!: boolean

  @Prop() error!: boolean

  public getImage(item: Corso) {
    if (!item?.assets?.length) {
      return '/img/post-thumb-2.jpg'
    }
    const image = item.assets.find(
      (asset) => asset.assetableField === 'mainImage'
    )
    if (!image) {
      return '/img/post-thumb-2.jpg'
    }
    return this.$config.cdnEndpoint + '512/' + image.originalName
  }

  public calculateCols(n: number) {
    if (n === 3) {
      return 'col-lg-4'
    }
    if (n === 2) {
      return 'col-lg-6'
    }
    return 'col-auto'
  }
}
