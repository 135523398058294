import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1f4a4d2a&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=1f4a4d2a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f4a4d2a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ElementsTheCarousel: require('/usr/src/nuxt-app/components/elements/TheCarousel.vue').default,ElementsTheAbout: require('/usr/src/nuxt-app/components/elements/TheAbout.vue').default,HomeTheAbout: require('/usr/src/nuxt-app/components/home/TheAbout.vue').default,HomeTheConvegni: require('/usr/src/nuxt-app/components/home/TheConvegni.vue').default,HomeTheCorsi: require('/usr/src/nuxt-app/components/home/TheCorsi.vue').default,HomeTheArticoli: require('/usr/src/nuxt-app/components/home/TheArticoli.vue').default,HomeThePartners: require('/usr/src/nuxt-app/components/home/ThePartners.vue').default})
