import { render, staticRenderFns } from "./ThePartners.vue?vue&type=template&id=39131165&scoped=true&"
import script from "./ThePartners.vue?vue&type=script&lang=ts&"
export * from "./ThePartners.vue?vue&type=script&lang=ts&"
import style0 from "./ThePartners.vue?vue&type=style&index=0&id=39131165&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39131165",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ElementsTheCarousel: require('/usr/src/nuxt-app/components/elements/TheCarousel.vue').default})
