
import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class TheAbout extends Vue {
  visibilityChanged(visible: boolean, entry: any) {
    if (visible) {
      entry.target.classList.add('show')
      entry.target.classList.remove('hidden')
    }
  }

  visibilityChangedT(visible: boolean, entry: any) {
    if (visible) {
      entry.target.classList.add('show-t')
      entry.target.classList.remove('hidden-t')
    }
  }
}
