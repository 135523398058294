
import { Component, Vue, Prop } from 'nuxt-property-decorator'
// @ts-ignore
import Swiper from '@/node_modules/swiper/swiper-bundle.min'
import 'swiper/swiper-bundle.min.css'

type Carousel = {
  title: string
  image?: string
  link?: string | null
}

@Component
export default class TheCarousel extends Vue {
  @Prop() items!: Carousel[]
  @Prop() breakpoints!: any
  @Prop({ default: false }) loop!: boolean
  @Prop({ default: 'carouselSwiper' }) swiperId!: string

  public defaultBreakpoint = {
    1650: {
      slidesPerView: 6,
      spaceBetween: 50,
    },
    1198: {
      slidesPerView: 5,
      spaceBetween: 40,
    },
    750: {
      slidesPerView: 3,
      spaceBetween: 15,
    },

    320: {
      slidesPerView: 2,
      spaceBetween: 10,
    },
  }

  mounted() {
    const breakpoints = this.breakpoints || this.defaultBreakpoint
    const loop = this.loop ? this.loop : false
    const swiperElement = document.getElementById(this.swiperId)

    if (swiperElement !== undefined && swiperElement !== null) {
      new Swiper(swiperElement, {
        slidesPerView: 1,
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        breakpoints,
        loop,
      })
    }
  }
}
