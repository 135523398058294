import { render, staticRenderFns } from "./TheAbout.vue?vue&type=template&id=8fb11a18&scoped=true&"
import script from "./TheAbout.vue?vue&type=script&lang=ts&"
export * from "./TheAbout.vue?vue&type=script&lang=ts&"
import style0 from "./TheAbout.vue?vue&type=style&index=0&id=8fb11a18&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8fb11a18",
  null
  
)

export default component.exports