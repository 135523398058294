export default function meta(
  title: string | undefined,
  description: string | undefined,
  image: string | undefined,
  link: string
) {
  description = cleanDescription(description)
  return {
    title: title + ' | ' + process.env.APP_NAME,
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: description,
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: title,
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: description,
      },
      {
        hid: 'og:image',
        property: 'og:image',
        content: image,
      },
      {
        hid: 'og:url',
        property: 'og:url',
        content: link,
      },
      {
        hid: 'twitter:card',
        name: 'twitter:card',
        content: 'summary_large_image',
      },
      {
        hid: 'twitter:title',
        name: 'twitter:title',
        content: title + ' | Associazione',
      },
      {
        hid: 'twitter:description',
        name: 'twitter:description',
        content: description,
      },
      {
        hid: 'twitter:image',
        name: 'twitter:image',
        content: image,
      },
    ],
    link: [
      {
        hid: 'canonical',
        rel: 'canonical',
        href: link,
      },
    ],
  }
}

function removeTags(str: string | undefined) {
  if (str === null || str === '' || str === undefined) return ''
  else str = str.toString()
  return str.replace(/(<([^>]+)>)/gi, '')
}

function cutDescription(str: string) {
  if (str.length >= 160) {
    return str.substring(0, 160)
  }
  return str
}

function cleanDescription(str: string | undefined) {
  str = removeTags(str)
  return cutDescription(str)
}
