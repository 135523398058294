
import { Component, Vue, Getter } from 'nuxt-property-decorator'
import axios from 'axios'
import { ListResponse, Model } from '@/types/Response'
import meta from '@/helpers/meta'
import { Convegno } from '~/types/models/Convegno'
import { Corso } from '~/types/models/Corso'
import { Articolo } from '~/types/models/Articolo'

type Carousel = {
  title: string
  image?: string
  link?: string | null
  subtitle?: string | null
  bgImage?: string | null
}

@Component
export default class HomePage extends Vue {
  @Getter('layout/isMobile') isMobile!: boolean
  public convegni: Convegno[] = []
  public articoli: Articolo[] = []
  public corsi: Corso[] = []
  public loaded = false

  public readonly items: Carousel[] = [
    {
      title: 'Convegni',
      link: '/convegni',
      subtitle: 'Promossi dagli Associati della Associazione',
      bgImage: 'img/generic-2.jpg',
    },
    {
      title: 'Corsi',
      link: '/corsi',
      subtitle: 'Corsi di formazione professionali',
      bgImage: 'img/generic-4.jpg',
    },
    {
      title: 'News & Eventi',
      link: '/news-eventi',
      subtitle: 'Tutte le attività del circuito degli Associati',
      bgImage: 'img/generic-3.jpg',
    },
  ]

  public readonly partners: Carousel[] = [
    {
      title: 'Ministero',
      link: 'https://www.giustizia.it/giustizia/',
      image: 'img/slide-1.jpg',
    },
    {
      title: 'Agenzia delle Entrate',
      link: 'https://www.agenziaentrateriscossione.gov.it/it/',
      image: 'img/slide-2.jpg',
    },
    {
      title: 'Portale delle vendite pubbliche',
      link: 'https://pvp.giustizia.it/pvp/',
      image: 'img/slide-3.jpg',
    },
    // {
    //   title: 'ASTEIVG',
    //   link: 'https://www.asteivg.com/',
    //   image: 'img/slide-4.jpg',
    // },
    {
      title: 'Dogane',
      link: 'https://www.adm.gov.it/portale/',
      image: 'img/slide-5.jpg',
    },
    {
      title: 'Repubblica',
      link: 'https://www.giustizia.it/',
      image: 'img/slide-6.jpg',
    },
    {
      title: 'Amministratori Giudiziari',
      link: '#',
      image: 'img/amministratori-giudiziari.webp',
    },
    {
      title: 'Sinageco',
      link: '#',
      image: 'img/sinageco.webp',
    },
  ]

  public head() {
    return meta(
      "Sito dell'Associazione Nazionale IVG",
      'L’Associazione Nazionale Istituti Vendite Giudiziarie ha per scopo il compimento di qualsiasi attività tendente alla tutela degli Istituti di Vendite Giudiziarie',
      this.$config.siteUrl,
      this.$config.siteUrl
    )
  }

  visibilityChanged(visible: boolean, entry: any) {
    if (visible) {
      entry.target.classList.add('show')
      entry.target.classList.remove('hidden')
    }
  }

  goToSection(event: any) {
    const el = document.getElementById('about')
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' })
    }
    event.preventDefault()
  }

  async fetch() {
    const urls = [
      this.$config.endpoint +
        'convegni?limit=3&orderBy[]=data_inizio&orderBy[]=desc',
      this.$config.endpoint +
        'corsi?attivo=1&limit=3&orderBy[]=data_inizio&orderBy[]=desc',
      this.$config.endpoint + 'articoli?limit=3&attivo=1',
    ]
    await axios
      .all(urls.map((url) => this.$axios.$get(url)))
      .then((responses: ListResponse<Convegno | Corso | Articolo>[]) => {
        this.convegni = responses[0]?.items as Convegno[]
        this.corsi = responses[1].items as Corso[]
        this.articoli = responses[2].items as Articolo[]
      })
      .catch((e: any) => {
        throw e
      })
      .finally(() => {
        this.loaded = true
      })
  }

  fetchOnServer() {
    return false
  }
}
